@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #000 ;
  font-family: "Montserrat", sans-serif;
}

:root {
  --color-default: #000;
  --color-second: #40176C;
  --color-light: #EBE0FD;
  --color-white: #FFFFFF;
  --color-purple-light: #EBE0FD;
  --color-light-lights: #F7F3FE;
  --color-light-light: rgba(255, 255, 255, 0.679);
}
.bg-purple-light{
  background-color: #EBE0FD !important;
}

.dark{
  color: var(--color-second) !important;
}
.ml100 {
  margin-left: 100px !important;
}

.ml50 {
  margin-left: 50px !important;
}



.mr50 {
  margin-right: 50px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.margin60 {
  margin: 60px 0;
}

.margin50 {
  margin: 50px 0;
}

.margin70 {
  margin: 70px 0;
}

.margin80 {
  margin: 80px 0;
}

.margin100 {
  margin: 100px 0;
}

.margin40 {
  margin: 40px 0;
}

.margin30 {
  margin: 30px 0;
}

.margin20 {
  margin: 20px 0;
}

.padding60 {
  padding: 60px;
}

.padding50 {
  padding: 50px;
}

.padding70 {
  padding: 70px;
}

.padding100 {
  padding: 100px 0;
}

.padding40 {
  padding: 40px;
}

.padding30 {
  padding: 30px;
}

.padding20 {
  padding: 20px;
}

.padding10 {
  padding: 10px;
}

.navbar img {
  width: 80%;
}
.navbar .nav-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.navbar .nav-list li {
  list-style: none;
  margin: 10px 20px;
}
.navbar .nav-list li a {
  text-decoration: none;
  color: var(--color-default);
}
.navbar .nav-list li .host {
  padding: 20px 40px;
  border-radius: 30px;
  background-color: var(--color-second);
  color: var(--color-white);
}
.navbar .nav-list li .host:hover {
  border: 2px solid var(--color-second);
  background-color: transparent;
  color: var(--color-second) !important;
}

.pic85 img {
  width: 85%;
}

.pic80 img {
  width: 80%;
}

.pic75 img {
  width: 75%;
}

.pic65 img {
  width: 65%;
}

.pic90 img {
  width: 90%;
}
.pointer{
  cursor: pointer;
}
.banner .set2 h2 {
  font-size: 50px;
}
.banner .set2 p {
  font-size: 20px;
}
.banner .set2 .micro {
  display: flex;
  align-items: center;
  border: 2px solid var(--color-second);
  padding: 3px 20px;
  width: 70%;
  border-radius: 36px;
  cursor: pointer;
}
.banner .set2 .micro a{
  font-size: 16px;
  text-decoration: none;
  color: #40176C;
}
.banner .set2 .micro a:hover{
  color: #FFFFFF;
}
.banner .set2 .micro img {
  width: 50px;
  height: 50px;
  margin: 0 15px;
  -o-object-position: center;
     object-position: center;
}
.banner .set2 .micro:hover {
  background-color: var(--color-second);
}
.banner .set2 .micro:hover p {
  color: var(--color-white) !important;
}
.banner .set1 .top {
  background-color: var(--color-light);
  padding: 20px;
  border-radius: 20px 20px 0 0;
}
.banner .set1 .top img {
  width: 100%;
  -o-object-position: center;
     object-position: center;
}
.banner .set1 .bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-light-lights);
  padding: 10px;
  border-radius: 0 0 20px 20px;
}
.banner .set1 .bottom .play img {
  width: 35px;
  height: 35px;
}
.banner .set1 .bottom span {
  color: grey;
}
.banner .set1 .bottom .min {
  font-size: 12px;
  margin-left: 10px;
}

.section1 .set1 {
  position: relative;
}
.section1 .set1 .top img {
  width: 100%;
  border-radius: 20px 20px;
  -o-object-position: center;
     object-position: center;
}
.section1 .set1 .bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: var(--color-light-light);
  padding: 10px;
  border-radius: 0 0 20px 20px;
}
.section1 .set1 .bottom .play img {
  width: 35px;
  height: 35px;
}
.section1 .set1 .bottom span {
  color: grey;
}
.section1 .set1 .bottom .min {
  font-size: 12px;
  margin-left: 10px;
}
.section1 .set2 h3 {
  font-size: 35px;
}
.section1 .set2 ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.section1 .set2 ul li {
  list-style: none;
  background-color: var(--color-light);
  padding: 18px 30px;
  border-radius: 15px;
  font-weight: 500;
  color: var(--color-second);
  margin: 10px;
}


.section2 h3 {
  font-size: 35px;
}
.section2 .set1 {
  position: relative;
}
.section2 .set1 .top img {
  width: 100%;
  border-radius: 20px 20px;
  -o-object-position: center;
     object-position: center;
}
.section2 .set1 .bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: var(--color-light-light);
  padding: 10px;
  border-radius: 0 0 20px 20px;
}
.section2 .set1 .bottom img {
  width: 35px;
  height: 35px;
}
.section2 .set1 .bottom span {
  color: grey;
}
.section2 .set1 .bottom .min {
  font-size: 12px;
  margin-left: 10px;
}
.section2 .set2 {
  position: relative;
}
.section2 .set2 .top img {
  width: 100%;
  border-radius: 20px 20px;
  -o-object-position: center;
     object-position: center;
}
.section2 .set2 .bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.35);
  padding: 10px;
  border-radius: 0 0 20px 20px;
}
.section2 .set2 .bottom div {
  margin: 0 20px;
}
.section2 .set2 .bottom .play {
  background-color: rgba(0, 0, 0, 0.5333333333);
  width: 40px;
  height: 40px;
  padding-top: 5px;
  border-radius: 15px;
  border: 2px solid var(--color-white);
}
.section2 .set2 .bottom .play img {
  width: 30%;
}
.section2 .set2 .bottom span {
  color: white;
}
.section2 .set2 .bottom p {
  color: white;
  font-weight: 600;
}
.section2 .set2 .bottom .min {
  font-size: 13px;
}
.section2 .see {
  display: flex;
  align-items: center;
  justify-content: center;
}
.section2 .see b {
  margin-right: 10px;
  color: var(--color-second);
  font-size: 15px;
}

.section3 {
  background-color: var(--color-second);
  background-image: url(../assets/image/Background.png);
}
.section3 img {
  width: 80%;
}
.section3 .text {
  padding-top: 50px;
  color: var(--color-white) !important;
}
.send a span{
 background-color: var(--color-second);
  border-radius: 30px;
  text-decoration: none;
  color: white !important;
  text-align: center;
  width: 200px;
  outline: none;
  border: none;
  padding: 10px 30px;
}
.section3 .text span {
  color: var(--color-white);
  text-transform: uppercase;
  font-size: 13px;
}
.section3 .text p {
  color: var(--color-white) !important;
}
.section3 .text h3 b {
  font-size: 35px;
  color: var(--color-white);
}
.section3 .text a {
  background-color: rgba(0, 0, 0, 0.4392156863);
  border-radius: 30px;
  text-decoration: none;
  color: white;
  outline: none;
  border: none;
  padding: 10px 30px;
}

.banner1 {
  background-color: var(--color-light);
  border-radius: 20px;
  padding: 30px;
}
.banner1 .first {
  display: flex;
  align-items: center;
}
.banner1 .first img {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}
.banner1 .first span {
  font-size: 13px;
}
.banner1 .second {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.banner1 .second .first img {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}
.banner1 .second .second {
  margin: 20px;
}
.banner1 .second .second img {
  width: 20px;
  height: 20px;
  margin-right: 50px;
}

.section4 .each {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 15px;
  background-color: var(--color-light);
}
.section4 .each img {
  width: 182.3481445313px;
  height: 203.592590332px;
  border-radius: 15px;
  object-fit: cover;
}
.section4 .each .text {
  margin-left: 15px;
}
.section4 .each .text p, .section4 .each .text span {
  color: #0E0E0E;
}
.section4 .each .text p {
  font-size: 15px;
}
.section4 .each .text span {
  font-size: 12px;
}

.footer .divider {
  display: flex;
  flex-wrap: wrap;
}
.footer ul li {
  list-style: none;
  margin: 10px;
}
.footer ul li a {
  text-decoration: none;
  color: inherit;
}

.btn-purple{
  text-align: center;
  padding: 15px 20px;
  margin-top: 20px;
  border-radius: 30px;
  background-color: var(--color-second);
  color: var(--color-white);
}
.btn-purple:hover{
  border: 2px solid var(--color-second);
  background-color: transparent;
  color: grey !important;
}

.btn-outline-purple{
  text-align: center;
  padding: 15px 20px;
  margin-top: 20px;
  border-radius: 30px;
  border: 2px solid var(--color-second);
  background-color: transparent;
  color: var(--color-second);

  
}
.btn-outline-purple:hover{
  background-color: var(--color-second);
  color: var(--color-white);
}
.icon-bg{
  min-height: 250px;
  /* width: 60%; */
  background-color: #EBE0FD;
  padding: 20px 40px;
  border-radius: 10px;

}

.text-bg{
  min-height: 250px;
/* 
  width: 80%; */
  margin: auto 0;
  background-color: #EBE0FD;
  padding: 20px 40px;
  border-radius: 10px;
  
}

.benfit-bg{
  min-height: 300px;
  /* width: 100%; */
  background-color: #EBE0FD;
  padding: 20px 40px;
  border-radius: 10px;
}
.text-bg h1{
 font-size: 70px;
 color: #000  !important;
}
/* .modal-content{
  background-color: #40176C !important;
} */
.modal-content .modal-title, h1, small{
  color: white !important;
}

.modal-content  h5{
  color: #eaeaea !important;
}
.rangeDetails{
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 2.5px;
  text-align: center;
  background-color: #11061d;

}
.rangeDetails h3, small{
  color: white;
  
}
.border-right{
  border-right: 1px solid #fff;
  
}
.modal-header {
  border-bottom: 0px !important;
}
.modal-footer{
  justify-content: flex-start !important;
  border-top: 0px !important;
  margin-left: 15px;
}
.modal-footer .btn-primary{
  background-color: #EBE0FD ;
  border-radius: 30px;
  color: #40176C;
  border: 0px !important;
  padding: 15px 40px;
}
.modal-footer .btn-primary:hover{
  background-color: #c1a1f4 ;
}


.modal-footer .btn-secondary{
  background-color: transparent;
  border-radius: 30px;
  color: #fff;
  border: 0px !important;
  padding: 15px 40px;
}

.modal-footer .btn-secondary:hover{
  background-color: #c1a1f4 ;

}
.form-range-thumb-bg{
  background-color: #40176C !important;
}

.loader-lg {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #40176C;
  width: 100px;
  height: 100px;

  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.loader {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #40176C;
  width: 30px;
  height: 30px;

  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media only screen and (max-width: 800px) {
  .text-bg{
    width: 100%;
  }
  .icon-bg{
    width: 100%;
  }
  .navbar .nav-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 50px 0;
    background-color: var(--color-second);
  }
  .navbar .nav-list li {
    list-style: none;
    margin: 10px 20px;
  }
  .navbar .nav-list li a {
    text-decoration: none;
    color: var(--color-white);
  }
  .navbar .nav-list li .host {
    padding: 15px 30px;
    margin-top: 20px;
    border-radius: 30px;
    background-color: var(--color-white);
    color: var(--color-second);
  }
  .banner h2 {
    font-size: 40px;
  }
  .banner .micro {
    width: 100% !important;
  }
}
@media only screen and (max-width: 500px) {
  .navbar .nav-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 30px 0;
    flex-direction: column;
    background-color: var(--color-second);
  }
  .navbar .nav-list li {
    list-style: none;
    margin: 10px 20px;
  }
  .navbar .nav-list li a {
    text-decoration: none;
    color: var(--color-white);
  }
  .banner h2 {
    font-size: 40px;
  }
  .banner .micro {
    width: 100% !important;
  }
}

.form-control{
  padding: 30px 20px !important;
}